<template>
<v-app>
    <v-app-bar app color="primary" dark>
        <v-toolbar-title>
            <img v-if="$vuetify.breakpoint.mobile" src="@/assets/img/logo_sem_texto.svg" height="48" alt="IFMT" />
            <img v-else src="@/assets/img/logo.svg" height="48" alt="IFMT" />
        </v-toolbar-title>
    </v-app-bar>

    <v-main>
        <v-container fluid>
            <v-row justify="center" class="mt-15">
                <v-stepper non-linear vertical>
                    <v-stepper-step step="1" editable>
                        Dados do servidor
                    </v-stepper-step>

                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-text-field :loading="loading" v-model="Item.nome" label="Nome" :error-messages="errors['nome']"></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field :loading="loading" v-model="Item.email" label="Email" :error-messages="errors['email']"></v-text-field>
                                    </v-row>

                                    <v-row>
                                        <v-col>
                                            <v-text-field :loading="loading" v-model="Item.cargo" label="Cargo" :error-messages="errors['cargo']"></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field :loading="loading" v-model="Item.funcao" label="Função" :error-messages="errors['funcao']"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field :loading="loading" v-model="Item.formacao" label="Área de Formação Acadêmica" :error-messages="errors['formacao']">
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-select :loading="loading" :items="nivel_formacao" v-model="Item.nivel_formacao" item-text="text" item-value="value" :error-messages="errors['nivel_formacao']" label="Nível"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="darken-1" text @click="save">Salvar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-step step="2" editable>
                        Habilidades técnicas
                    </v-stepper-step>

                    <v-stepper-content step="2">
                        <v-card class="mx-auto" max-width="800">
                            <v-card-text>
                                <v-container>
                                    <v-simple-table :dense="true" :fixed-header="true" :height="300">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">Habilidade</th>
                                                    <th class="text-left">Conhecimento</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in computedHabilidades" :key="item.id">
                                                    <td>{{ item.nome }}</td>
                                                    <td>
                                                        <v-menu offset-y>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span v-bind="attrs" v-on="on">
                                                                    {{
                                      conhecimentoFilter(item.conhecimento).text
                                    }}
                                                                </span>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item v-for="c in conhecimento" :key="c.value" @click="updateConhecimento(item, c)">
                                                                    <v-list-item-title>{{
                                      c.text
                                    }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                </v-stepper>
            </v-row>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
import {
    RecursoHumano,
    HabilidadeServidor
} from "@/api/eqpdti";
import {
    Habilidade
} from "@/api/core";
export default {
    props: {
        uid: String,
    },

    data() {
        return {
            Item: {},
            errors: {},
            habilidades: [],
            nivel_formacao: [{
                    value: 1,
                    text: "Ensino Fundamental",
                },
                {
                    value: 2,
                    text: "Ensino Médio",
                },
                {
                    value: 3,
                    text: "Ensino Médio - Profissionalizante",
                },
                {
                    value: 4,
                    text: "Superior",
                },
                {
                    value: 5,
                    text: "Pós-Graduação - Especialização",
                },
                {
                    value: 6,
                    text: "Pós-Graduação - Mestrado",
                },
                {
                    value: 7,
                    text: "Pós-Graduação - Doutorado",
                },
            ],
            conhecimento: [{
                    value: 0,
                    text: "Nenhum",
                },
                {
                    value: 1,
                    text: "Noção",
                },
                {
                    value: 2,
                    text: "Informação",
                },
                {
                    value: 3,
                    text: "Entendimento",
                },
                {
                    value: 4,
                    text: "Proeficiência",
                },
                {
                    value: 5,
                    text: "Fluência",
                },
            ],
            items: [],
            loading: false,
        };
    },
    computed: {
        computedHabilidades: function () {
            let data = [];
            let _items = [...this.items];
            this.habilidades.forEach((item) => {
                if (item.conhecimento != 0) {
                    let f = _items.filter((i) => i.id == item.habilidade);
                    if (f.length > 0) {
                        f[0].conhecimento = item.conhecimento;
                        data.push(f[0]);
                        _items = _items.filter((i) => i.id != f[0].id);
                    }
                }
            });

            data = [...data, ..._items];
            return data;
        },
    },
    methods: {
        conhecimentoFilter(id) {
            let f = this.conhecimento.filter((i) => i.value == id);
            if (f.length > 0) {
                return f[0];
            }
            return this.conhecimento[0];
        },
        remove(item) {
            console.log(item);
        },
        save() {
            this.loading = true;
            RecursoHumano.savePerfil(this.uid, this.Item)
                .then(() => {
                    this.loading = false;
                    this.$emitter.emit("alert:success", "Dados atualizados.");
                })
                .catch(() => {
                    this.loading = false;
                    this.$emitter.emit(
                        "alert:error",
                        "Não foi possível salvar os dados."
                    );
                });
        },
        updateConhecimento(item, conhecimento) {
            HabilidadeServidor.post(this.Item.id, {
                servidor: this.Item.id,
                habilidade: item.id,
                conhecimento: conhecimento.value,
            }).then(() => {
                HabilidadeServidor.list(this.uid).then(({
                    data
                }) => {
                    this.habilidades = data;
                    this.$emitter.emit('alert:info', 'Atualizado.')
                });
            });
        },
    },
    created() {
        this.loading = true;
        RecursoHumano.perfil(this.uid).then(({
            data
        }) => {
            this.loading = false;
            this.Item = data;
        });
        Habilidade.list().then(({
            data
        }) => {
            this.items = data;
        });
        HabilidadeServidor.list(this.uid).then(({
            data
        }) => {
            this.habilidades = data;
        });
    },
};
</script>
